import Footer1 from "../../components/footer/Footer";
import Footer2 from "../../components/footer/Footer2";
import Contactpagebnr from "../../components/Homebanner/Contactpagebnr";
import Contactpgesections from "../../components/Homestay-components/Contactpgesections";
import TransparentNavbar from "../../components/navbar/Navbar";
import Navbar2 from "../../components/navbar/Navbar2";
import Newheader from "../../components/navbar/Newheader";

const Contact = () => {
  return (
    <div>

     <Contactpagebnr />
      <Contactpgesections />
  
    </div>
  );
};

export default Contact;
