import React from "react";
import Video123 from "../../../src/img/newimg/1127.mp4";
import sicottage from "../../img/newimg/1.png";
import silverton from "../../img/newimg/3.jpg";
import horizonvilla from "../../img/newimg/layer1.png";
import loghouse from "../../img/newimg/2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong, faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const BigSlider = () => {
  return (
    <section className="bigslider">
          <div className="container">
            <div className="box">
              <div className="main-text">
                <h4>OUR EXQUISITE <br />PROPERTIES </h4>
              </div>
              <div className="box1">
                <h2>Villas. Suites. <br /> Cottages</h2>
              </div>
            </div>
            <div className="Industriesserve-wrapper ">
              <div className="Industriesserve-slider">
                <Swiper
                  spaceBetween={30}
                  slidesPerView={3}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: true,
                  }}
                  loop={true}
                  navigation={{
                    nextEl: ".custom-next",
                    prevEl: ".custom-prev",
                  }}
                  modules={[Autoplay, Navigation]}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                    },
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
                >
               
                  <SwiperSlide>
                    <div className="firstslide">
                      <img src={sicottage} alt="The SI Cottage" />
                      <div className="custom-slider-text">
                        <h3 className="custom-slider-title"><a href="/hotels/the-si-cottage">The SI Cottage</a></h3>
                        <p className="custom-slider-description">Mussoorie, Uttrakhand, India</p>
                        <h4 className="custom-slider-content">₹ 28000/ <span className="night"> per night </span></h4>
                        <a href="/hotels/the-si-cottage">
                          <button className="custom-button">
                            <FontAwesomeIcon icon={faArrowRightLong} />
                          </button>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>

                 
                  <SwiperSlide>
                    <div className="secondslide">
                      <img src={horizonvilla} alt="The Horizon Villa" />
                      <div className="custom-slider-text">
                        <h3 className="custom-slider-title">     <a href="/hotels/the-horizon-villa">The Horizon Villa</a></h3>
                        <p className="custom-slider-description">Mussoorie, Uttrakhand, India</p>
                        <h4 className="custom-slider-content">₹ 28000/ <span className="night"> per night </span></h4>
                        <a href="/hotels/the-horizon-villa">
                          <button className="custom-button">
                            <FontAwesomeIcon icon={faArrowRightLong} />
                          </button>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>

                   
                  <SwiperSlide>
                    <div className="threeslide">
                      <img src={silverton} alt="The Silverton Chalet" />
                      <div className="custom-slider-text">
                        <h3 className="custom-slider-title">  <a href="/hotels/the-silverton-chalet">The Silverton Chalet</a></h3>
                        <p className="custom-slider-description">Mussoorie, Uttrakhand, India</p>
                        <h4 className="custom-slider-content">₹ 18000/ <span className="night"> per night </span></h4>
                        <a href="/hotels/the-silverton-chalet">
                          <button className="custom-button">
                            <FontAwesomeIcon icon={faArrowRightLong} />
                          </button>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>

                 
                  <SwiperSlide>
                    <div className="fourthslide">
                      <img src={loghouse} alt="The Log House" />
                      <div className="custom-slider-text">
                        <h3 className="custom-slider-title"> <a href="/hotels/the-log-house">The Log House</a></h3>
                        <p className="custom-slider-description">Mussoorie, Uttrakhand, India</p>
                        <h4 className="custom-slider-content">₹ 7500/ <span className="night"> per night </span></h4>
                        <a href="/hotels/the-log-house">
                          <button className="custom-button">
                            <FontAwesomeIcon icon={faArrowRightLong} />
                          </button>
                        </a>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>

               
                <div className="custom-navigation0">
                  <div className="custom-prev">
                    <FontAwesomeIcon icon={faArrowLeftLong} />
                  </div>
                  <div className="custom-next">
                    <FontAwesomeIcon icon={faArrowRightLong} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        <video className="background-video" src={Video123} autoPlay loop muted />
      
    </section>
  );
};

export default BigSlider;
