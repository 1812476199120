import Footer1 from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import Offerbnner from "../../components/Homebanner/Offerbnner";
import Offerpagesection from "../../components/Homestay-components/Offerpagesection";
import Faqs from "../../components/Homestay-components/Faqs";
import Aboutcenter from "../../components/Homestay-components/Aboutcenter";
import Homeheader from "../../components/navbar/Homeheader";
import TransparentNavbar from "../../components/navbar/Navbar";
import Navbar2 from "../../components/navbar/Navbar2";
import Footer2 from "../../components/footer/Footer2";
import Newheader from "../../components/navbar/Newheader";

const Home = () => {
  return (
    <div>
         {/* <TransparentNavbar /> */}
      {/* <Homeheader /> */}
      {/* <Navbar /> */}
      <Offerbnner />
      <Offerpagesection />
      <Faqs />
    </div>
  );
};

export default Home;
