import React, { Component } from "react";
import Slider from "react-slick";
import  collabpick1 from "../../img/collaboration-picks/collabpick1.jpg";
import  collabpick2 from "../../img/collaboration-picks/collabpick2.jpg";
import  collabpick4 from "../../img/collaboration-picks/collabpick4.jpg";
import  collabpick3 from "../../img/collaboration-picks/collabpick3.jpg";
import  collabpick5 from "../../img/collaboration-picks/collabpick5.png";
import  collabpick8 from "../../img/collaboration-picks/collabpick8.jpg";


function Tagsgallery() {
  const settings = {
   slidesToShow: 4,
    autoplay: true,
    infinite: true,
    speed: 500,
    dots: true,
    slidesToScroll: 1,
   
    responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
  };
  return (
    <section className="collab-gallery">
       <div className="container">
    <div className="row text-center">
      <div className="col-xxl-12 col-md-12">
       <h5 className="new-sub-heading">Captured highlights </h5>
       <h2 className="new-heading">Snapshots of some unforgettable memories </h2>
           </div>
        </div>
        <div className="center-slider22">
      <Slider {...settings}>
    
        <div>
        <div className="coll-piks1">
      <img src={collabpick1} className="colab1" alt="" />
      </div>
        </div>
        <div className="fdf">
        <div className="coll-piks2">
      <img src={collabpick2} className="colab1" alt="" />
      </div>
        </div>
        <div>
        <div className="coll-piks1">
      <img src={collabpick4} className="colab1" alt="" />
      </div>
        </div>
        <div className="fdf">
        <div className="coll-piks2">
      <img src={collabpick3} className="colab1" alt="" />
      </div>
        </div>
        <div>
        <div className="coll-piks1">
      <img src={collabpick8} className="colab1" alt="" />
      </div>
        </div>
        
        <div className="fdf">
        <div className="coll-piks2">
      <img src={collabpick5} className="colab1" alt="" />
      </div>
        </div>
    
      </Slider>
      </div>
    </div>
         
    </section>
  );
}

export default Tagsgallery;
