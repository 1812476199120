import React from "react";
import SearchList3 from "../../components/searchlist/searchlist3";
import SimpleSlider from "../../components/searchlist/newslider";
import image1 from "../../../src/img/newimg/design1.png";
import newblog from "../../../src/img/newimg/image (3).jpg";
import Newtab from "../../components/searchlist/newtab";
import ExploreSlider from "../../components/searchlist/exploreslider";
import BigSlider from "../../components/searchlist/wowitsnotworking";
import tinyimg from "../../img/newimg/tinyimg.png";
import Doubleslider from "../../components/newsite/Doubleslider";
import Doubleslidermobile from "../../components/newsite/Doubleslidermobile";
import Video123 from "../../../src/img/newimg/video123.mp4";
import Review2slider from "../../components/newsite/Review2slider";
 
 

function homenew() {
  return (
    <div>
     
      <section className="mynewbnner1">
        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
          <div className="row">
            <div className="contec1">
              <img src={tinyimg} className="tinyimg" alt="tinyimg" />
              <p className="figmabnrtext1">
                Your gateway to extraordinary staycations{" "}
              </p>
              <div className="Proding1">
                <a href="/villa-collections" className="explore-button1">
                  Explore Now <span className="arrow1">→</span>
                </a>
              </div>
            </div>
          </div>
          <div className="overlay"></div>
          <video
            src={Video123}
            className="Figmabnr1"
            autoPlay
            loop
            muted
            playsInline
          ></video>

        </div>
      </section>

      <section className="seachsection" id="booking1">
        <div className="container-fluid topform">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
              <div className="list-outer-search1">
                <SearchList3 />
            
              </div>
            </div>
          </div>
        </div>
      </section>
      <SimpleSlider />
      {/* <SecondSlider /> */}

      <BigSlider />
      
      <Doubleslider />
      <Doubleslidermobile />
      {/* <CustomSwiper /> */}
      <section className="new-one">
        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
          <div className="new-two">
            <h5>CAPTURED HIGHLIGHTS</h5>
            <h2 className="text-white">
              Snapshots of some <br></br> unforgettable memories
            </h2>
          </div>
          <img src={image1} alt="" />
        </div>
      </section>
      <Newtab />
      <Review2slider />
      <ExploreSlider />
      <div className="container">
        <div className="last-box">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="imagesectionsdf">
                <img src={newblog} alt="" />
              </div>
            </div>

            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
              <div className="text-sectionsdf">
                <h2>
                  Book your <br></br>getaway now!
                </h2>
                <p>
                  Let us be your host as you create cherished memories away from
                  the hustle and bustle. Book your stay with us and open doors
                  to a world of luxury, comfort and heartfelt hospitality.
                </p>

                <div className="Proding2">
                  <a href="#booking1" className="explore-button1">
                    Book Now <span className="arrow1">→</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default homenew;
