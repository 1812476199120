import React from 'react'
import locate from "../../../src/img/newimg/Group.png";
import star from "../../../src/img/newimg/star.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong, faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";


function Review2slider() {
    return (

        <section className='Review2slider'>
            <div className='container'>
                <div className="box">
                    <div className="main-text">
                        <h4>DELIGHTED WORDS <br></br>THAT KEEP OUR <br></br> WINGS SOARING</h4>
                    </div>
                    <div className="box1">
                        <h2>Rave  <br></br> reviews </h2>
                    </div>
                </div>

                <div className='allriew'>
                    <Swiper
                        spaceBetween={30}
                        slidesPerView={3}
                        autoplay={{
                          delay: 2000, 
                          disableOnInteraction: true,
                        }}
                        loop={true}
                        navigation={{
                            nextEl: ".custom-next",
                            prevEl: ".custom-prev",
                        }}
                        modules={[Autoplay, Navigation]}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                            },
                            640: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 2,
                            },
                            1024: {
                                slidesPerView: 3,
                            },
                        }}
                    >
                        <div className="as-slider">

                            <SwiperSlide>
                                <div className="riw">
                                    <div className="review-swiper-slider slider-1">
                                        <img src={locate} alt="Cozy Fireplace Evenings" className="quotss" />
                                        <h3 className="new-name">Ramit </h3>
                                        <img src={star} alt="" className="starts" />
                                        <p>
                                        I must compliment the choice of interiors by the owners. It's a beautiful villa and a very practical location. The staff is also courteous and I highly recommend this place to all!
                                        </p>

                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="review-swiper-slider slider-2">
                                    <img src={locate} alt="Cozy Fireplace Evenings" className="quotss" />
                                    <h3 className="new-name">Sanidhya</h3>
                                    <img src={star} alt="" className="starts" />
                                    <p>
                                    Place as clean and beautiful as mentioned in the photos. The staff was excellent, hospitality and food was amazing . Every corner was beautifully made and decorated . We really loved the bonfire and the food . 
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="review-swiper-slider slider-3">
                                    <img src={locate} alt="Cozy Fireplace Evenings" className="quotss" />
                                    <h3 className="new-name">Ankita</h3>
                                    <img src={star} alt="" className="starts" />
                                    <p>
                                        Place as clean and beautiful as mentioned in the photos. The staff was excellent, and the hospitality and food were amazing. Every corner was beautifully made and decorated. We really loved the bonfire and the food.
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="review-swiper-slider slider-1">
                                    <img src={locate} alt="Cozy Fireplace Evenings" className="quotss" />
                                    <h3 className="new-name">Vandan Ved</h3>
                                    <img src={star} alt="" className="starts" />
                                    <p>
                                        The apartment was super clean and felt very safe to stay in corona times. The owner was super helpful and provided a domestic help at all times who would do things like cooking and cleaning which was absolutely amazing.
                                    </p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="review-swiper-slider slider-2">
                                    <img src={locate} alt="Cozy Fireplace Evenings" className="quotss" />
                                    <h3 className="new-name">Sahil</h3>
                                    <img src={star} alt="" className="starts" />
                                    <p>
                                        Excellent hospitality and support from Mrs Kaur and the property caretaker. Would like to recommend others to visit as well. Thanks
                                    </p>
                                </div>
                            </SwiperSlide>
                        </div>
                    </Swiper>
                <div className="custom-navigation0">
                    <div className="custom-prev">
                        <FontAwesomeIcon icon={faArrowLeftLong} />
                    </div>
                    <div className="custom-next">
                        <FontAwesomeIcon icon={faArrowRightLong} />
                    </div>
                </div>
                </div>
            </div>
        </section>
    )
}

export default Review2slider