import React from "react";
import gallerypick1 from "../../img/newimg/gallerypick1.png";
import gallerypick4 from "../../img/newimg/gallerypick4.png";
import gallerypick7 from "../../img/newimg/gallerypick7.png";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const Doubleslidermobile = () => {
    return (
        <section className="Doubleslidermobile">
            <div className="container">
            <div className="row text-center" id="nestled">
                <div className="col-xxl-5 col-xl-5 col-md-12 col-12">
                    <h2>Step into<br />
                    luxury living</h2>
                </div>
                <div className="col-xxl-7 col-xl-7 col-md-12 col-12">
                <div className="curated">
                    <p className="">So Many Mornings offers a curated collection of premium <br />accommodations nestled amid magnificent views that <br />evoke a sense of luxury and timeless aesthetics.</p>
                    </div>
                </div>
            </div>
            <div className="dbs">
                <Swiper
                    spaceBetween={30}
                    slidesPerView={3}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: true,
                    }}
                    loop={true}
                    navigation={{
                        nextEl: ".custom-next",
                        prevEl: ".custom-prev",
                    }}
                    modules={[Autoplay, Navigation]}
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                        },
                        640: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 2,
                        },
                      
                    }}
                >

                    <div className="mobile-slider">

                        <SwiperSlide>
                            <div className="gallerypick1">
                                <img src={gallerypick1} alt="ddlider" className="gallerypick1" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="gallerypick1">
                                <img src={gallerypick4} alt="ddlider" className="gallerypick1" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="gallerypick1">
                                <img src={gallerypick7} alt="ddlider" className="gallerypick1" />
                            </div>
                        </SwiperSlide>

                    </div>
                </Swiper>
                </div>
                <div className="row">
            <div className="Proding2">
                    <a href="#booking1" className="explore-button1">
                        Book Now <span className="arrow1">→</span>
                    </a>
                </div>
            </div>
            </div>
        </section>
    );
};

export default Doubleslidermobile;
