import Footer1 from "../../components/footer/Footer";
import Footer2 from "../../components/footer/Footer2";
import Staysbnner from "../../components/Homebanner/Staysbnner";
import Aboutsections from "../../components/Homestay-components/Aboutsections";
import Sliderproperties from "../../components/Homestay-components/Sliderproperties";
import Stayssections from "../../components/Homestay-components/Stayssections";
import Homeheader from "../../components/navbar/Homeheader";
import TransparentNavbar from "../../components/navbar/Navbar";
import Navbar from "../../components/navbar/Navbar";
import Navbar2 from "../../components/navbar/Navbar2";
import Newheader from "../../components/navbar/Newheader";

const Villacollection = () => {
  return (
    <div>
      {/* <TransparentNavbar /> */}
      {/* <Homeheader /> */}
      {/* <Navbar /> */}
      <Staysbnner />
      <Stayssections />
      <Sliderproperties />
    </div>
  );
};

export default Villacollection;
