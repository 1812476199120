import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import Footer1 from "../components/footer/Footer";
import Homeheader from "../components/navbar/Homeheader";
import TransparentNavbar from "../components/navbar/Navbar";
import Navbar from "../components/navbar/Navbar";
import Navbar2 from "../components/navbar/Navbar2";
import Footer2 from "../components/footer/Footer2";
import Newheader from "../components/navbar/Newheader";

const Termsconditions = () => {
  const backgroundImg = "/assets/img//bg/inrbnr.webp";
  const pageTitle = "Terms and conditions";
  const pageSubheading =
    "Rules, expectations and guidelines that govern our relationship with you.";

  return (
    <div>
      {/* <TransparentNavbar /> */}
      {/* <Homeheader /> */}
      <Breadcrumb
        backgroundImg={backgroundImg}
        title={pageTitle}
        subheading={pageSubheading}
      />
      <section className="privacypolicy">
        <div className="container">
          <div className="row">
            <h2>Terms and conditions </h2>
            <div className="out-box-content">
              <p>
                These Terms and Conditions are applicable to all the guests or
                customers who make bookings of any of the Properties for
                staycation through our website. You’re advised to read and
                understand all the Terms and Conditions mentioned on our website
                before using this website and making a booking of any property.{" "}
              </p>
              <p>
                If you do not agree to any of these Terms and Conditions, please
                do not use our website and do not make bookings for any of the
                Properties.
              </p>
              <p>
                For the purpose of bookings made through our website, we may use
                certain terms in our Terms and Conditions, which have the
                following meanings:
              </p>
              <ul>
                <li>
                  “Property/Properties” include villas, chalets, cottages and
                  homestays available for booking.{" "}
                </li>
                <li>
                  “Guest/Guests” refers to any individuals/ companies or other
                  entities interested in booking our properties for a
                  staycation.
                </li>
                <li>
                  {" "}
                  “Booking” refers to a reservation made by any Guest in any of
                  our properties for a stay.{" "}
                </li>
              </ul>
              <h3>Consent to the terms</h3>
              <p>
                The terms and conditions included in our website are a binding
                agreement between you and So Many Mornings with respect to the
                use of this website and the services. By accessing our website
                or any of our services, applications, contents, functions or
                making a Booking, you acknowledge and consent to have read,
                understood and agreed to all the terms and conditions outlined
                on the website and agree to be bound by them.
              </p>
              <h3>Copyright </h3>
              <p>
                So Many Mornings does not grant you any express or implied
                rights or licenses under any patents, trademarks, copyrights or
                other proprietary or intellectual property rights. You agree not
                to copy any of the content from this website onto another
                website or in any other media. You hereby agree not to use any
                device, software or routine to interfere or attempt to interfere
                with the working of our website or any transaction being
                conducted via this website. You hereby agree not to copy,
                reproduce, alter, modify, create derivative works, or publicly
                display any content without our prior written consent.
              </p>
              <p>
                You consent that the information you provide us shall not
                contain any viruses that may intend to damage our systems or
                interfere with the working of our website.{" "}
              </p>
              <h3>Intellectual property rights </h3>
              <p>
                Unless stated otherwise, all intellectual property rights
                related to the content, information and materials on our website
                are wholly and exclusively owned by So Many Mornings.
                Intellectual Property shall include but not be limited to all
                developments, proprietary information, trademarks, trade names,
                logos, artwork, slogans, service marks, domain names, brand
                names, know-how, processes, methods, trade secrets, designs and
                all other work protected by copyright. You are prohibited from
                copying, publishing, promoting, marketing, integrating,
                utilizing, combining or otherwise using the content or our
                brand. Any unlawful use or any of the aforementioned actions or
                behavior will result in your termination of use of our website
                or legal action.{" "}
              </p>
              <h3>Accuracy of information </h3>
              <p>
                We have taken appropriate measures and precautions in content
                preparation to ensure that all the details are accurately
                displayed. However, we do not guarantee the accuracy and
                timeliness of information provided on the website. We may not be
                liable for any damage or loss sustained by you due to the usage
                of information provided on the website.{" "}
              </p>
              <h3>Additional services </h3>
              <p>
                So Many Mornings at its sole discretion arrange supplementary
                services, including chefs, cleaners, etc. through outsourcing.
                Guests shall understand and agree that such services are not
                included in the booking amount and may incur additional charges.
                We provide these additional services solely for the convenience
                of Guests without any commitment or obligation on our part.{" "}
              </p>
              <h3>Pricing </h3>
              <p>
                The tariff rates provided on the Website are non-negotiable and
                may be subject to change without prior notice. The inclusion or
                exclusion of applicable taxes for the Properties is contingent
                upon the specific type of Property. The mentioned tariffs may be
                susceptible to inaccuracies due to technical glitches. In the
                event of such errors, which are beyond our control, we reserve
                the right to rectify the mistake. After correction, the
                incorrect and erroneous tariff will not be enforceable.{" "}
              </p>
              <h3>Rights to bookings and property possession </h3>
              <p>
                In case of any Booking, it is explicitly understood and
                acknowledged by all the Guests that they do not and will not,
                get the possession, charge or claim to possess any of the booked
                properties at any time. Guests are prohibited from checking into
                the Property before or at the time of Check-In unless they
                comply with specified requirements. Failure to comply with these
                prescribed requirements will render Guests ineligible for any
                refund of the Booking Amount or Deposit and no compensation of
                any kind shall be claimable by Guests or payable by So Many
                Mornings.
              </p>
              <ul>
                <li>
                  Guests must provide Booking Confirmation issued by So Many
                  Mornings along with the proof/details of full payments
                </li>
                <li>
                  In adherence to the government security regulations, every
                  Guest must mandatorily provide original government-issued
                  photo IDs for verification along with a copy of the photo IDs,
                  on or before check-in.
                </li>
                <li>
                  If any guest provides incorrect and false information during
                  Booking, we reserve the right to cancel the booking, deny
                  entry to the Property and if the Guests have already
                  checked-In, evict them from the Property. In such
                  circumstances, the Deposits and Booking Amount paid by the
                  Guests shall not be refunded and no compensation shall be paid
                  by So Many Mornings.
                </li>
              </ul>
              <p>
                During check-in or throughout the stay, the total number of
                Guests or visitors of the Guests shall not exceed the number
                mentioned at the time of Booking. So Many Mornings, at its sole
                discretion, may permit additional persons to stay at the
                Property for an extra cost.
              </p>
              <h3>Promotional offers </h3>
              <p>
                By providing your contact details on the website, you consent to
                receive promotional content from So Many Mornings through
                WhatsApp, email, push notifications, and SMS. So Many Mornings
                may periodically offer you various discounts, offers and
                promotions. The terms and conditions for discounts, offers, or
                promotional schemes will be clearly outlined on the website and
                may be subject to change. We reserve the right to modify or
                terminate any promotional scheme without prior notice.
              </p>
              <h3>Booking confirmation </h3>
              <p>
                Guests acknowledge and agree that making advance payments,
                paying a security deposit or receiving a Booking Confirmation
                means that guests have unconditionally accepted the booking
                details. It's important to note that the issuance of a Booking
                Confirmation does not guarantee the confirmation of the booking
                itself. So Many Mornings is not obligated to fulfill a booking
                unless the entire booking amount is paid by the Guest through
                the designated payment mode, even if a Booking Confirmation has
                been sent to the Guest. The Booking Confirmation becomes null
                and void if the full booking amount is not received by us by the
                specified due dates.
              </p>
              <h3>Check-in and check-out time </h3>
              <p>
                Check-in and Check-out times are specified in the relevant
                Booking Confirmation. We advise Guests to adhere to the check-in
                time and not arrive at the Property before the stated Check-In
                time as it would be difficult to accommodate the Guests arriving
                before Check-In time. Guests agree not to stay at the Property
                beyond the Check-out time mentioned in the Booking Confirmation.{" "}
              </p>
              <h3>Advance payment </h3>
              <p>
                Guests are required to make an advance payment before receiving
                the Booking Confirmation as a security to safeguard So Many
                Mornings against potential revenue loss due to cancellations or
                unpaid balances. If the Advance is not received, we will not
                send the booking confirmation to the Guest.
              </p>
              <p>
                Guests must promptly inspect and report any damages to the
                Property, including equipment, appliances, cutlery, crockery,
                utensils, furniture, fittings, furnishing, and structure.
                Failure to report damages will hold the Guests accountable, and
                they must reimburse us without delay. During their stay, Guests
                must use the Property and its contents with utmost care. Upon
                Check-Out, the Guest is responsible for leaving the Property in
                good and reasonably clean condition, excluding normal usage. If
                the repair/replacement cost exceeds the security deposit, Guests
                will be notified in writing and must settle the excess amount
                within 7 days.
              </p>
              <h3>Limitation of Liability </h3>
              <p>
                So Many Mornings, its affiliates, and their respective officers,
                employees, agents, or representatives shall not be liable for
                any direct, indirect, incidental, special, consequential, or
                punitive damages arising from or related to your use of the
                properties, services, or any information provided. This includes
                but is not limited to, damages for loss of profits, goodwill,
                use, data, or other intangible losses, even if So Many Mornings
                has been advised of the possibility of such damages. We shall
                not be held responsible for any loss or damage to personal
                belongings or valuables during your stay.
              </p>
              <h3>Force Majeure </h3>
              <p>
                So Many Mornings shall not be held liable or responsible for any
                failure or delay in performing its obligations under this
                agreement when such failure or delay is due to circumstances
                beyond its reasonable control, including but not limited to acts
                of nature, war, terrorism, government actions, labor disputes,
                power failures, or any other force majeure event. During the
                occurrence of a force majeure event, So Many Mornings reserves
                the right to suspend or terminate its obligations under this
                agreement without incurring any liability. If such circumstances
                prevent or delay your use of the property or services, So Many
                Mornings shall not be responsible for any resulting losses,
                damages, or inconvenience.
              </p>
              <h3>Indemnification</h3>
              <p>
                You agree to indemnify, defend, and hold harmless So Many
                Mornings, its affiliates, and their respective officers,
                employees, agents, and representatives from and against any and
                all claims, liabilities, damages, losses, costs, expenses, or
                fees (including reasonable attorneys' fees) arising out of or in
                connection with:
              </p>
              <ul>
                <li>
                  {" "}
                  Your use of the properties, services, or information provided
                  by So Many Mornings.
                </li>
                <li>
                  Violation of any terms or conditions outlined in this
                  agreement.
                </li>
                <li>
                  Any third-party claims alleging infringement of intellectual
                  property rights, privacy rights, or any other rights.
                </li>
              </ul>
              <h3>Waiver</h3>
              <p>
                The failure of So Many Mornings to exercise or enforce any right
                or provision of the Terms and Conditions mentioned on this
                website will not constitute a waiver of such right or provision.
              </p>
              <h3>Governing law</h3>
              <p>
                So Many Mornings develops and maintains this website, according
                to Indian legislation. Any disputes, claims or differences with
                regards to the Booking or in respect of any matters arising out
                of the Booking, shall be governed by and construed in accordance
                with the Indian laws
              </p>
              <h3>Changes to the Terms of Use</h3>
              <p>
                We may change these terms and conditions from time to time.
                You’re advised to visit this page periodically to stay updated
                with our latest terms and conditions.{" "}
              </p>

              <h3>Contact us </h3>
              <p>
                If you have any questions or queries regarding the Terms and
                Conditions, feel free to reach out to us via email at{" "}
                <a href="mailto:somanymornings@gmail.com">
                  somanymornings@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
};

export default Termsconditions;
