import React from "react";
import Navbar2 from "../components/navbar/Navbar2";
import TransparentNavbar from "../components/navbar/Navbar";
import Footer2 from "../components/footer/Footer2";
import doulpick1 from "../img/doulpick1.jpg";
import graphic from "../img/graphic.png";
import Tagreel1 from "../img/collaboration-picks/Tagreel1.mp4";
import Tagreel2 from "../img/collaboration-picks/Tagreel2.mp4";
import Tagreel3 from "../img/collaboration-picks/Tagreel3.mp4";
import Tagreel4 from "../img/collaboration-picks/Tagreel4.mp4";
import Testimonials from "../components/newsite/testimonials";
import doulpick2 from "../img/doulpick2.jpg";
import Collaborationbnner from "../components/Homebanner/Collaborationbnner";
import Tagsgallery from "../components/newsite/Tagsgallery";
import Newheader from "../components/navbar/Newheader";
import Review2slider from "../components/newsite/Review2slider";

const Collaborations = () => {
  return (
    <div>

      {/* <TransparentNavbar /> */}
      <section className="Collabrtion">
        <Collaborationbnner />
      </section>
      <section className="abt-collab">
        <div className="container">
          <div className="row">
            <div
              className="col-xxl-6-col-xl-6 col-lg-6 col-md-12 col-12"
              id="airy"
            >
              <div className="double-imge">
                <div className="row">
                  <div class="image-left col-md-6 col-sm-6">
                    <img src={doulpick1} className="doulpick1" alt="" />
                  </div>
                  <div class="image-right col-md-6 col-sm-6">
                    <img src={doulpick2} className="doulpick1" alt="" />
                  </div>
                </div>
              </div>
              <div class="image_background">
                <img src={graphic} className="graphic" alt="" />
              </div>
            </div>
            <div className="col-xxl-6-col-xl-6 col-lg-6 col-md-12 col-12">
              <div className="colla-abt">
                <div className="style_new sc-heading article_heading ">
                  <div className="content-heading">
                    <h2 className="new-heading">
                      {" "}
                      Let’s make your special occasions worth cherishing{" "}
                    </h2>
                    <div className="description-heading">
                      <p className="Coast">
                        We provide a perfect backdrop to commemorate your
                        special events and significant milestones. Whether it’s
                        a corporate retreat, a fun birthday gathering, a
                        picturesque pre-wedding photoshoot, a lively
                        bachelorette bash, a heartwarming reunion or a milestone
                        anniversary celebration, our villas set the stage to
                        turn your event into a cherished memory.
                      </p>
                    </div>
                  </div>
                  <div className="background-heading">Collaboration</div>
                  <div className="button-heading">
                    <a href="/About">About Us</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Tagsgallery />

      <section className="celebrity-visit">
        <div className="container">
          <div className="row text-center">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
              <h5 className="new-sub-heading">
                Renowned personalities we hosted{" "}
              </h5>
              <h2 className="new-heading">Celebrity & Influencer spotlight </h2>
            </div>
            <div className="row" id="exclus00">
              <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                <div className="Trireel">
                  <video
                    controls
                    autoPlay={"autoplay muted"}
                    preLoad="auto"
                    loop
                    id="pseudo-partial-border"
                  >
                    <source src={Tagreel3} type="video/mp4" />
                  </video>
                </div>
              </div>
              {/* <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-12">
                <div className="Trireel">
                  <video controls loop id="pseudo-partial-border">
                    <source src={Tagreel1} type="video/mp4" />
                  </video>
                </div>
              </div> */}
              <div
                className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12"
                id="singlereel"
              >
                <div className="Trireel">
                  <video controls loop id="pseudo-partial-border">
                    <source src={Tagreel2} type="video/mp4" />
                  </video>
                </div>
              </div>

              <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-12">
                <div className="Trireel">
                  <video controls loop id="pseudo-partial-border">
                    <source src={Tagreel4} type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="sandhu">
        <section className="client-reviews">
          <div className="container">
            <div className="row text-center">
              <div className="col-xxl-12 col-md-12">
                <h5 className="new-sub-heading">
                  Delighted words that keep our wings soaring
                </h5>
                <h2 className="new-heading">Rave reviews </h2>
              </div>
            </div>
          </div>
        </section>
        <Review2slider/>
        {/* <Testimonials /> */}
      </div>

      <section className="lastcta">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-12 col-md-12 col-12">
              <div className="outboxer">
                <h5 className="new-sub-heading">Book your getaway now! </h5>
                <h3 className="new-heading-2">
                  Let us be your host as you create cherished memories away
                  from the hustle and bustle. Book your stay with us and open
                  doors to a world of luxury, comfort and heartfelt hospitality.{" "}
                </h3>
                <div className="signupbtn">
                  <a href="/Register">SIGN UP</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
};

export default Collaborations;
