import React from "react";
import Slider from "react-slick";
import Video123 from "../../../src/img/newimg/1127.mp4";
import locate0 from "../../../src/img/newimg/elevenoaks.webp";
import locate11 from "../../../src/img/newimg/horizonvilla.webp";
import locate22 from "../../../src/img/newimg/loghouse.webp";
import locate33 from "../../../src/img/newimg/minihorizon.webp";
import locate44 from "../../../src/img/newimg/Pinkmangolia3.webp";
import locate55 from "../../../src/img/newimg/sicottage.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong, faArrowRightLong, } from "@fortawesome/free-solid-svg-icons";

const NextArrow = ({ onClick }) => (
    <button className="custom-arrow custom-next" onClick={onClick} aria-label="Next Slide">
        <FontAwesomeIcon icon={faArrowRightLong} />
    </button>
);

const PrevArrow = ({ onClick }) => (
    <button className="custom-arrow custom-prev" onClick={onClick} aria-label="Previous Slide">
        <FontAwesomeIcon icon={faArrowLeftLong} />
    </button>
);

function SecondSlider() {
    const secondSliderSettings = {
        dots: false,
        infinite: true,
        // speed: 500,
        // autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    const sliderItems = [
        {
            img: locate0,
            title: "The SI Cottage",
            description: "The Mall Road, Mussoorie",
            content: "₹ 28000/ "
        },
        {
            img: locate11,
            title: "The Horizon Villa",
            description: "The Mall Road, Mussoorie",
            content: "₹ 28000/"
        },
        {
            img: locate22,
            title: "The SI Cottage",
            description: "The Mall Road, Mussoorie",
            content: "₹ 24000/"
        },
        {
            img: locate33,
            title: "The Pink Magnolia Cottage",
            description: "The Mall Road, Mussoorie",
            content: "₹ 24000/"
        },
        {
            img: locate44,
            title: "The Mini Horizon",
            description: "The Mall Road, Mussoorie",
            content: "₹ 5000/"
        },
        {
            img: locate55,
            title: "The Log House",
            description: "The Mall Road, Mussoorie",
            content: "₹ 75000/"

        }
    ];

    return (
        <div className="slider-container">

            <div className="boxx">

                <div className="video-container">
                    <video className="background-video"
                        src={Video123}
                        width={1400}
                        autoPlay
                        loop
                        muted
                        playsInline
                    ></video>
                </div>
                <div className="container">
                    <div className="box">
                        <div className="main-text">
                            <h4>OUR EXQUISITE <br /> PROPERTIES</h4>
                        </div>
                        <div className="box1">
                            <h1>Villas. Suites. <br /> Cottages</h1>
                        </div>
                    </div>
                </div>
                {/* Slider */}
                <div className="container">
                    <Slider {...secondSliderSettings}>
                        {sliderItems.map((item, index) => (
                            <div key={index} className="custom-slider-item">

                                <div className="immgg">
                                    <img
                                        src={item.img}
                                        alt={item.title}
                                        className={`custom-slider-img custom-img-${index + 1}`}
                                    />
                                </div>
                                <div className="custom-slider-text">
                                    <h3 className="custom-slider-title">{item.title}</h3>
                                    <p className="custom-slider-description">{item.description}</p>
                                    <h4 className="custom-slider-content">{item.content}<span className="night"> per night </span></h4>
                                    <a href="/home-new">     <button className="custom-button">
                                        <FontAwesomeIcon icon={faArrowRightLong} />
                                    </button>
                                    </a>
                                </div>

                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
}

export default SecondSlider;
