import React from "react";
import img1 from "../../img/newimg/gallerypick4.png";
import img4 from "../../img/newimg/gallerypick1.png";
import img2 from "../../img/newimg/horizonvilla21.webp";
import img3 from "../../img/newimg/Rectangle1.jpg";
import img5 from "../../img/newimg/horizonvilla40.webp";
import img6 from "../../img/newimg/horizonvilla14.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const Doubleslider = () => {
    return (
        <section className="doubleslider">

            <div className="container">
                <div className="row" id="nestled">
                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-12">
                        <h2>Step into<br />
                            luxury living</h2>
                    </div>
                    <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-12">
                        <div className="curated">
                            <p className="">So Many Mornings offers a curated collection of premium <br />accommodations nestled amid magnificent views that <br />evoke a sense of luxury and timeless aesthetics.</p>
                        </div>
                    </div>
                </div>
                <div className="dbsb">
                    <Swiper
                        spaceBetween={30}
                        slidesPerView={3}
                        autoplay={{
                            delay: 2000,
                            disableOnInteraction: true,

                        }}
                        loop={true}
                        navigation={{
                            nextEl: ".custom-next",
                            prevEl: ".custom-prev",
                        }}
                        modules={[Autoplay, Navigation]}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                            },
                            640: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 2,
                            },
                            1024: {
                                slidesPerView: 3,
                            },
                        }}
                    >

                        <div className="desktop-slider">

                            <SwiperSlide>
                                <div className="ddlider">
                                    <img src={img1} alt="ddlider" className="ddlider1" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ddlider20">
                                    <img src={img2} alt="ddlider" className="ddlider2" />
                                    <img src={img3} alt="ddlider" className="ddlider0" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ddlider">
                                    <img src={img4} alt="ddlider" className="ddlider1" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="ddlider20">
                                    <img src={img5} alt="ddlider" className="ddlider2" />
                                    <img src={img6} alt="ddlider" className="ddlider0" />
                                </div>
                            </SwiperSlide>
                        </div>

                    </Swiper>
                </div>
                <div className="row">

                    <div className="Proding2">
                        <a href="#booking1" className="explore-button1">
                            Book Now <span className="arrow1">→</span>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Doubleslider;
