import React, { useState } from 'react';
import Video123 from "../../../src/img/newimg/1127.mp4";
import Couples from "../../../src/img/newimg/preffer1.png";
import Couples1 from "../../../src/img/newimg/preffer2.png";
import Couples2 from "../../../src/img/newimg/preffer3.png";
import Couples3 from "../../../src/img/newimg/preffer4.png";


function Newtab() {

    const [activeTab, setActiveTab] = useState(1);
    return (
        <>
            <section className='newtabs0'>
                <div className="boxx">
                    <div className='container '>
                        <div className="box">
                            <div className="main-text">
                                <h4>PICK YOUR IDEAL <br></br>ESCAPE</h4>
                            </div>
                            <div className="box1">
                                <h2>We’ve got options for  <br></br>every
                                    traveller’s needs</h2>
                            </div>
                        </div>
                    </div>
                    <div className="video-container">
                        <video className="background-video"
                            src={Video123}
                            width={1400}
                            autoPlay
                            loop
                            muted
                            playsInline
                        ></video>

                    </div>
                    <div className='container'>
                        <div className='row'>
                            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                                <div className="single-property-detail">
                                    <div className='row'>
                                        <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-12'>

                                            {activeTab === 1 && (
                                                <div className="hotelDetails">
                                                    <img src={Couples} alt="" />
                                                </div>
                                            )}

                                            {activeTab === 2 && (
                                                <div className="hotelDetails">
                                                    <img src={Couples1} alt="" />

                                                </div>
                                            )}

                                            {activeTab === 3 && (
                                                <div className="hotelDetails">
                                                    <img src={Couples2} alt="" />

                                                </div>
                                            )}
                                            {activeTab === 4 && (
                                                <div className="hotelDetails">

                                                    <img src={Couples3} alt="" />

                                                </div>
                                            )}
                                        </div>
                                        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12 tabs'>
                                            <div className='all-buttons'>
                                                <button
                                                    className={activeTab === 1 ? "tab-btn active" : "tab-btn"}
                                                    onClick={() => setActiveTab(1)}
                                                >
                                                    <p className="property-dec1">Couples</p>
                                                    <p> 2 Guests</p>
                                                </button>
                                                <button
                                                    className={activeTab === 2 ? "tab-btn active" : "tab-btn"}
                                                    onClick={() => setActiveTab(2)}
                                                >
                                                    <p className="property-dec1">Small Families</p>
                                                    <p> 3-4 Guests</p>
                                                </button>
                                                <button
                                                    className={activeTab === 3 ? "tab-btn active" : "tab-btn"}
                                                    onClick={() => setActiveTab(3)}
                                                >
                                                    <p className="property-dec1">Big Families</p>
                                                    <p> 5-14 Guests</p>
                                                </button>
                                                <button
                                                    className={activeTab === 4 ? "tab-btn active" : "tab-btn"}
                                                    onClick={() => setActiveTab(4)}
                                                >
                                                    <p className="property-dec1">Large Families</p>
                                                    <p> 14+ Guests</p>
                                                </button>
                                                <div className='sff'>
                                                    <a href="#booking1" className="explore-button1">
                                                        Book Now 
                                                    </a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
            <div className="single-property-detail">
                <div className='row'>
                    <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-12'>


                        {activeTab === 1 && (
                            <div className="hotelDetails">
                                <img src={Couples} alt="" />
                            </div>
                        )}

                        {activeTab === 2 && (
                            <div className="hotelDetails">
                               <img src={Couples1} alt="" />

                            </div>
                        )}

                        {activeTab === 3 && (
                            <div className="hotelDetails">
                                 <img src={Couples2} alt="" />

                            </div>
                        )}
                        {activeTab === 4 && (
                            <div className="hotelDetails">
                               
                                <img src={Couples3} alt="" />

                            </div>
                        )}
                    </div>
                    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12 tabs'>
                        <div className='all-buttons'>
                        <button
                            className={activeTab === 1 ? "tab-btn active" : "tab-btn"}
                            onClick={() => setActiveTab(1)}
                        >
                            <p className="property-dec1">Couples</p>
                            <p> 2 Guests</p>
                        </button>
                        <button
                            className={activeTab === 2 ? "tab-btn active" : "tab-btn"}
                            onClick={() => setActiveTab(2)}
                        >
                            <p className="property-dec1">Small Families</p>
                            <p> 3-4 Guests</p>
                        </button>
                        <button
                            className={activeTab === 3 ? "tab-btn active" : "tab-btn"}
                            onClick={() => setActiveTab(3)}
                        >
                            <p className="property-dec1">Big Families</p>
                            <p> 5-14 Guests</p>
                        </button>
                        <button
                            className={activeTab === 4 ? "tab-btn active" : "tab-btn"}
                            onClick={() => setActiveTab(4)}
                        >
                            <p className="property-dec1">Large Families</p>
                            <p> 14+ Guests</p>
                        </button>
                      
                    </div>
                      </div>
                </div>
                </div>
            </div> */}
                </div>

            </section>
        </>
    );
}

export default Newtab;
