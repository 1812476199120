import React, { useEffect } from "react";
import Slider from "react-slick";
import { useLocation, useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import './hotslider.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong, faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
const Hotelslider = () => {
  const location = useLocation();
  const hotelNameEncoded = location.pathname.split("/")[2];
  const hotelName = decodeURIComponent(hotelNameEncoded);

  const { data, loading, error } = useFetch(
    `https://www.api.somanymornings.com/api/hotels/findByName/${hotelName}`
  );

  const navigate = useNavigate();

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return <div className="custom-arrow11 next-arrow11" onClick={onClick}><FontAwesomeIcon icon={faArrowRightLong} /></div>;
  };

  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return <div className="custom-arrow11 prev-arrow11" onClick={onClick}><FontAwesomeIcon icon={faArrowLeftLong} /></div>;
  };

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="hotsliderimagediv">
      <Slider {...settings}>
        {data?.photos?.slice(0, 3).map((photo, i) => ( // Display up to 3 photos
          <div className="slide-property" key={i}>
            <img
              src={photo}
              alt={`Photo ${i + 1}`}
              className="hotelImg"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Hotelslider;
