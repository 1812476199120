import Footer1 from "../../components/footer/Footer";
import Aboutbanner from "../../components/Homebanner/Aboutbanner";
import Hotelsearchbnr from "../../components/Homebanner/Hotelsearchbnr";
import Aboutsections from "../../components/Homestay-components/Aboutsections";
import Hotelsearchsections from "../../components/Homestay-components/Hotelsearchsections";
import Homeheader from "../../components/navbar/Homeheader";
import TransparentNavbar from "../../components/navbar/Navbar";
import Navbar from "../../components/navbar/Navbar";
import Navbar2 from "../../components/navbar/Navbar2";
import Footer2 from "../../components/footer/Footer2";
import Newheader from "../../components/navbar/Newheader";

const Hotelsearch = () => {
  return (
    <div>
      <Hotelsearchbnr />
      <Hotelsearchsections />
    </div>
  );
};

export default Hotelsearch;
