import React from "react";
import hoping1 from "../../img/gallery/hoping1.png";
import hoping2 from "../../img/gallery/hoping2.png";
import hoping3 from "../../img/gallery/hoping3.png";
import hoping4 from "../../img/gallery/hoping4.png";

function Holidaytrip() {
  return (
    <>
      <section className="Hoop">
        <div className="container">
          <div className="row">
            <div
              className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12"
              id="teseting"
            >
              <div className="hoping">
                <div className="card-hoping">
                  <img src={hoping1} className="hoping1" alt="" />
                  <div className="info">
                    <h1>Bonfire </h1>
                    <p>
                      Enjoy enchanting evenings with your loved ones in the
                      warmth of a crackling bonfire.
                    </p>
                                </div>
                  <div className="Sight-Seeing">
                    <h3>Bonfire</h3>
                    <div className="discover">
                      <a href="/">DISCOVER MORE</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12"
              id="teseting2"
            >
              <div className="hoping">
                <div className="card-hoping">
                  <img src={hoping2} className="hoping1" alt="" />
                  <div className="info">
                    <h1> Nature trails </h1>
                    <p>
                      Explore a variety of scenic trails surrounded by lush
                      greenery and gorgeous landscapes.
                    </p>
                               </div>
                  <div className="Sight-Seeing">
                    <h3> Nature trails </h3>
                    <div className="discover">
                      <a href="/">DISCOVER MORE</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12"
              id="teseting"
            >
              <div className="hoping">
                <div className="card-hoping">
                  <img src={hoping3} className="hoping1" alt="" />
                  <div className="info">
                    <h1>Sightseeing</h1>
                    <p>
                    Discover the hidden gems, iconic sites, mesmerizing waterfalls and charming colonial buildings.
                    </p>
                                 </div>
                  <div className="Sight-Seeing">
                    <h3>Sightseeing</h3>
                    <div className="discover">
                      <a href="/">DISCOVER MORE</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xxl-3 col-xl-3 col-lg-6 col-md-6  col-12"
              id="teseting2"
            >
              <div className="hoping">
                <div className="card-hoping">
                  <img src={hoping4} className="hoping1" alt="" />
                  <div className="info">
                    <h1>Hiking & more</h1>
                    <p>
                    Experience a myriad of thrilling activities, including hiking, paragliding, zip-lining and much more.
                    </p>
                                 </div>
                  <div className="Sight-Seeing">
                    <h3>Hiking & more</h3>
                    <div className="discover">
                      <a href="/">DISCOVER MORE</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Holidaytrip;
